





























































































































































































































































































































import {Vue, Component} from 'vue-property-decorator';

import {accessCodeEnabled, appName, appVersion} from '@/env';
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readHasCreatorAccess,
  readHasNurseAccess,
  readHasPhysicianAccess,
  readHasReviewerAcccess,
} from '@/store/main/getters';
import {commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer} from '@/store/main/mutations';
import {dispatchUserLogOut} from '@/store/main/actions';

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/profile');
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  public appName = appName;
  public appVersion = appVersion;
  public selectedItem = 1;

  public accessCodeEnabled = accessCodeEnabled;

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(this.$store, !readDashboardShowDrawer(this.$store));
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(this.$store, !readDashboardMiniDrawer(this.$store));
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public get hasPhisicianAccess() {
    return readHasPhysicianAccess(this.$store);
  }

  public get hasNurseAccess() {
    return readHasNurseAccess(this.$store);
  }

  public get hasReviewerAccess() {
    return readHasReviewerAcccess(this.$store);
  }

  public get hasCreatorAccess() {
    return readHasCreatorAccess(this.$store) || this.hasAdminAccess;
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
